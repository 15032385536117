<template>
<div :class="{white:white,black:!white}">
  <div class="gray-bg3">
    <!-- <div class="fixed-box">
      <van-nav-bar
        title="舌诊成功"
        left-text=""
        @click-left="onClickLeft"
        fixed
      />
    </div> -->
    <div class="test-tongue">
      <div class="test-process flex-row">
        <div class="process-item flex-row">
          <div class="circle1"></div>
          <p class="title1">面诊</p>
        </div>
        <div class="line"></div>
        <div class="process-item flex-row">
          <div class="circle1"></div>
          <p class="title1">舌诊</p>
        </div>
        <div class="line"></div>
        <div class="process-item flex-row">
          <div class="circle1"></div>
          <p class="title1">问诊</p>
        </div>
      </div>
      <div class="img-center">
        <img
          src="../../public/assets/new_report/inquiry.png"
          alt=""
          class="inquiry-img"
        />
      </div>
      <p class="alarm-title transition-title">
        您即将进入<span style="color: #07b49a">[问诊]</span>
      </p>
      <div class="flex-row notice">
        <span>请如实填写问诊信息，避免检测结果出现误差</span>
      </div>
      <div class="test-btn" @click="onJump">开始问诊</div>
    </div>
  </div>
  <div class="hu-tongue">
    <div class="tongue-head">
        <div class="tongue-nood">
          <div class="nood active">
            <div class="circular"></div>
            <p>面诊</p>
          </div>
          <div class="bar"></div>
          <div class="nood active">
            <div class="circular"></div>
            <p>舌诊</p>
          </div>
          <div class="bar"></div>
          <div class="nood active">
            <div class="circular"></div>
            <p>问诊</p>
          </div>
        </div>
        <div class="face-nosis">
          <div class="nosis-bace"></div>
          <div class="nosis-img nosis-ask">
            <img src="../../public/assets/new_report/inquiry.png" alt />
          </div>
          <div class="nosis-text">
            您即将进入<span>[问诊]</span>
          </div>
          <div class="nosis-shot">
              <div class="test-btn" @click="onJump">开始问诊</div>
          </div>
        </div>
        <div class="tice-foot">
            <span class="ticeText">请如实填写问诊信息，避免检测结果出现误差</span>
        </div>
    </div>
  </div>
</div>
</template>
<script>
import "./new_test.scss";
export default {
  data() {
    return {
      white:this.$route.query.white
    };
  },
  methods: {
    onJump() {
      this.$router.push({
        path: "/question",
        query: { ...this.$route.query},
      });
    },
        onClickLeft: function () {
      this.$router.go(-1);
    },
  },
};
</script>