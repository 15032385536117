<template>
  <div :class="{white:white,black:!white}">
    <div class="gray-bg3">
      <!-- <div class="fixed-box">
        <van-nav-bar
          title="舌诊"
          left-text=""
          @click-left="onClickLeft"
          fixed
        />
      </div> -->
      <div class="test-tongue">
        <div class="test-process flex-row">
          <div class="process-item flex-row">
            <div class="circle active"></div>
            <p class="title active">面诊</p>
          </div>
          <div class="line"></div>
          <div class="process-item flex-row">
            <div class="circle active"></div>
            <p class="title active">舌诊</p>
          </div>
          <div class="line"></div>
          <div class="process-item flex-row">
            <div class="circle"></div>
            <p class="title">问诊</p>
          </div>
        </div>
        <p class="alarm-title">
          您即将进入<span style="color: #07b49a">[舌诊]</span>
        </p>

        <div class="flex-row notice">
          <div class="line"></div>
          <span>拍摄须知</span>
          <div class="line"></div>
        </div>
        <div class="flex-row notice-photo">
          <div
            class="flex-row notice-photo-item"
            v-for="(item, index) in tonguesList"
            :key="index"
          >
            <img :src="item.url" alt="" />
            <p class="photo-txt">{{ item.title }}</p>
          </div>
        </div>
      </div>
      <!-- <div class="face-notification">
        请不要带眼镜
        <br />保持面部在框定范围内
      </div> -->
      <div class="face-scan">
        <div class="img">
          <img :src="img || '/assets/face/tongue-eg.png'" alt />
        </div>
      </div>
      <div class="test-btn">
        <van-uploader :after-read="afterRead">
          <Button>开始拍摄</Button>
        </van-uploader>
        <!-- <div  class="secondary-operator">
          <van-uploader :after-read="afterRead">
            <a>重新拍摄</a>
          </van-uploader>
        </div> -->
        <!-- <Button v-if="status > 0" @click="onUpload">下一步</Button> -->
      </div>
      <div class="mask" v-if="loading">
        <div class="loading">
          <van-loading size="50px" color="#00c5cd" />
        </div>
      </div>
      <!-- 重新拍摄 -->
      <van-popup v-model:show="show" position="center" :round="show">
        <van-uploader :after-read="afterRead">
          <div class="modal-popup">
            <img
              src="../../public/assets/new_report/false.png"
              alt=""
              class="success-img"
            />
            <p class="sucess-title">舌诊失败，请参照示例重新拍摄</p>
            <img
              class="error-alarm"
              src="../../public/assets/new_report/tongues.png"
              alt=""
            />
            <div style="padding: 0 1.5rem">
              <div class="shot-btn">重新拍摄</div>
            </div>
          </div>
        </van-uploader>
      </van-popup>
    </div>
    <div class="hu-tongue">
      <div class="tongue-head">
          <div class="tongue-nood">
            <div class="nood active">
              <div class="circular"></div>
              <p>面诊</p>
            </div>
            <div class="bar"></div>
            <div class="nood active">
              <div class="circular"></div>
              <p>舌诊</p>
            </div>
            <div class="bar"></div>
            <div class="nood">
              <div class="circular"></div>
              <p>问诊</p>
            </div>
          </div>
          <div class="face-nosis">
            <div class="nosis-bace"></div>
            <div class="nosis-img">
              <img :src="img || '/assets/face/face-eg.png'" alt />
            </div>
            <div class="nosis-text">
              您即将进入<span>[舌诊]</span>
            </div>
            <div class="nosis-shot">
              <van-uploader :after-read="afterRead">
                <Button>开始拍摄</Button>
              </van-uploader>
            </div>
            <div class="flex-row notice">
              <div class="line"></div>
              <span>拍摄须知</span>
              <div class="line"></div>
            </div>
          </div>
          <div class="tice-foot">
            <div class="flex-row notice-photo">
              <div
                class="flex-row notice-photo-item"
                v-for="(item, index) in tonguesList1"
                :key="index"
              >
                <img :src="item.url" alt="" />
                <p class="photo-txt">{{ item.title }}</p>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
import Button from "../components/common/button";
import { uploadPhoto } from "../services/api";
import { Toast , Notify } from "vant";
import Exif from "exif-js";
import "./new_test.scss";
import { ref } from 'vue';
export default {
  components: { Button },
     setup() {
    const show = ref(false);
    const showPopup = () => {
      show.value = true;
    };
    const hiddenPopup = () => {
      show.value = false;
    };
    return {
      show,
      showPopup,
      hiddenPopup
    };
  },
  data() {
    return {
      status: 0,
      img: "",
      loading: false,
      tonguesList: [
        {
          url: require("../../public/assets/new_report/photo-alarm1.png"),
          title: "请将舌头尽量平展伸出",
        },
        {
          url: require("../../public/assets/new_report/photo-alarm2.png"),
          title: "请将舌头对准像框正中央",
        },
      ],
      tonguesList1: [
        {
          url: require("../../public/assets/new_report/photo-alarm1-1.png"),
          title: "请将舌头尽量平展伸出",
        },
        {
          url: require("../../public/assets/new_report/photo-alarm2-1.png"),
          title: "请将舌头对准像框正中央",
        },
      ],
      white:this.$route.query.white
    };
  },
  methods: {
    onUpload() {
      this.loading = true;
      uploadPhoto(2, this.img)
        .then((res) => {
          if (res.code === 0) {
            if (res.data.pass) {
              this.onNext(res.data.id);
              if(document.body.clientWidth>1000){
                Notify({ type: 'success', message: "舌诊成功，请进行下一步" });
              }else{
                Toast.success("舌诊成功，请进行下一步");
              }
              
            } else {
              this.status = 2;
              this.showPopup();
              if(document.body.clientWidth>1000){
                Notify("舌诊失败，请参照示例重新拍摄");
              }else{
                Toast.fail("舌诊失败，请参照示例重新拍摄");
              }
            }
          }
        })
        .finally(() => {
          //this.showPopup()
          this.loading = false;
        });
    },
    afterRead({ file }) {
      this.handleImg(file);
      this.hiddenPopup()
      this.status = 1;
    },
    handleImg(file) {
      const self = this;
      loadImage(file, { maxWidth: 600, canvas: true, orientation: true }).then(
        (data) => {
          self.img = data.image.toDataURL("image/jpeg", 0.75);
          this.onUpload();
        }
      );
    },
    onNext: function (id) {
      this.$router.push({
        path: "/transition",
        query: { ...this.$route.query, tid: id },
      });
    },
    onClickLeft: function () {
      this.$router.go(-1);
    },
  },
};
</script>
